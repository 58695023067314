import axios from 'axios';
import store from './store';

const baseURL = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_BACKEND_BASE_URL // Production base URL
  : ''; // Empty string for development (assuming proxy is handling it)

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'x-schema': process.env.VUE_APP_SCHEMA
    },
    baseURL,
    withCredentials: true
});

api.interceptors.request.use(
    async config => {

        const currentTime = Date.now();
        const tokenExpiry = store.getters["getTokenExpiry"];
        const isLoggedIn = store.getters["isLoggedIn"];
        if (!tokenExpiry || !isLoggedIn) return config;
        if (currentTime >= Date.parse(tokenExpiry)) {        // Check if the user's auth token has expired
            console.log("before refresh")
            const refreshResponse = await store.dispatch('refresh') // replace newTokenExpiry with the new expiry time
            .catch((err) => {
                store.commit('logout');
            })
        }
        return config;
    },

    async error => {
        // Do something with request error
        console.log(error)
        console.log("logging error from axios instance")
        return Promise.reject(error);
    }
);
export default api;
